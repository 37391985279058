export const inputs = {
  entity: 'inputs',
  schema: ['type', 'category'],
  keys: {
    inputs: 'id',
    type: 'id',
    category: 'id',
  },
};

export const inputCategories = {
  entity: 'inputCategories',
  schema: [],
  keys: {
    inputCategories: 'id',
  },
};

export const inputTypes = {
  entity: 'inputTypes',
  schema: [],
  keys: {
    inputTypes: 'id',
  },
};

export const inputLog = {
  entity: 'inputLog',
  schema: [],
  keys: {
    inputLog: 'id',
  },
};
