import React from 'react';
import PropTypes from 'prop-types';
import Container from './styles';

const TextArea = ({ label, name, placeholder, value, onChange, width }) => (
  <Container width={width}>
    {label && <label>{label}</label>}
    <div>
      <textarea
        id={`field-${name}`}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  </Container>
);

TextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  value: PropTypes.any,
};

TextArea.defaultProps = {
  width: '100%',
};

export default TextArea;
