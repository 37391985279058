import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';

export const Styles = styled.div`
  width: calc(100% + 30px);
  height: 100%;
  overflow: hidden;
  position: relative;

  > div[role='grid'] {
    border-spacing: 0;
    width: 100%;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 15px;
    position: relative;
    height: 100%;
    margin: 0;

    > div.body {
      position: relative;
      top: 0;
      left: -15px;
      padding: 0 15px;
      height: 100%;
      margin: 22px 0;
    }

    button {
      border: none;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
    }

    > *:not(img) {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 99999 1 auto;
      &:focus {
        outline: none;
      }
    }

    div[role='row'] {
      flex: 1 0 auto;
      display: flex;
      background-color: #3f4c5b;
      border-radius: 6px;
      cursor: ${ifProp('selectable', 'pointer', 'normal')};

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      ${ifProp(
        'selectable',
        css`
          transition: 0.2s;
          &:hover {
            background-color: #627488;
          }
        `
      )}
    }

    div.header {
      position: absolute;
      top: 0;
      left: -15px;
      z-index: 1;
      height: 20px;
      padding: 0 15px;
      div[role='row'] {
        background-color: transparent;
      }
    }

    div[role='cell'] {
      height: 100%;
      border-radius: 2px;
      color: #fff;
      font-size: 14px;
      line-height: 40px;
      padding: 0 5px 0 20px;
      white-space: nowrap;
      overflow: hidden;
      position: relative;
      max-width: 100%;
      text-overflow: ellipsis;
    }

    .option {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;
      background: transparent;
      padding: 0;
      > .material-icons {
        color: #c4c4c4;
        font-size: 20px;
        transition: 0.2s;
        cursor: pointer;
        &:hover {
          color: #b18fd2;
        }
      }
      &:focus {
        outline: none;
      }
      &:disabled {
        opacity: 0.4;
        pointer-events: none;
        cursor: not-allowed;
      }
    }

    .text-danger {
      color: #f26529;
    }

    .text-warning {
      color: #461e6e;
    }

    .text-bold {
      font-weight: bold;
    }

    div[role='columnheader'] {
      position: relative;
      resize: both;
      padding: 0 5px 8px 20px;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 500;
      font-variant: small-caps;
      font-feature-settings: 'cpsp' on;
      > div > button {
        color: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: #aeaeae;
        font-weight: 600;
        text-align: center;
        width: 100%;
        &:focus {
          outline: none;
        }
      }
    }

    .header-menu {
      position: absolute;
      background: #fbfbfb;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      width: 200px;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      color: #4f4f4f;
      display: none;
      z-index: 9999;
      > button {
        line-height: 30px;
        padding: 0 5px;
        transition: 0.2s;
        text-align: left;
        &:focus {
          outline: none;
        }
        &:hover {
          background-color: #e1e1e1;
        }
      }
      .filter {
        padding: 5px;
        > input {
          width: 100%;
          height: 30px;
          background: transparent;
          border-bottom: 1px solid #ccc;
          color: #4f4f4f;
          &::placeholder {
            opacity: 0.7;
            color: #4f4f4f;
          }
        }
      }
    }

    .noData {
      width: 100%;
      padding: 20px;
      background-color: rgba(255, 255, 255, 0.2);
      text-align: center;
    }
  }

  div[role='grid'] {
    position: relative;
  }
`;
