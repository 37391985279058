import React from 'react';
import Icon from '../Icon';
import Container from './styles';

const Loading = () => (
  <Container>
    <Icon icon="faCircleNotch" className="fa-spin" style={{ fontSize: 35 }} />
  </Container>
);

export default Loading;
