import React, { useRef } from 'react';
import usePerfectScrollbar from '../../hooks/usePerfectScrollbar';
import Icon from '../Icon';
import { Container, Close } from './styles';

const Modal = ({ children, onClose }) => {
  const container = useRef();
  usePerfectScrollbar(container, true, true, true);
  return (
    <Container ref={container}>
      <Close onClick={onClose}>
        <Icon icon="faTimes" style={{ fontSize: 16 }} />
      </Close>
      {children}
    </Container>
  );
};

export default Modal;
