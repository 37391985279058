import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer, toast, Flip } from 'react-toastify';
import CollapseMenuProvider from '../../contexts/CollapseMenuContext';
import GlobalStyles from '../../components/GlobalStyles';
import theme from '../../config/theme';
import store from '../../config/store';
import 'react-toastify/dist/ReactToastify.css';

const Wrapper = ({ children }) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router basename={process.env.REACT_APP_BASENAME}>
        <CollapseMenuProvider>
          <GlobalStyles />
          <ToastContainer
            autoClose={5000}
            position={toast.POSITION.BOTTOM_LEFT}
            transition={Flip}
            className="toast-container"
          />
          {children}
        </CollapseMenuProvider>
      </Router>
    </ThemeProvider>
  </Provider>
);

export default Wrapper;
