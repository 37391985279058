import styled from 'styled-components';

export default styled.div`
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);

  label {
    font-size: 12px;
  }

  > div {
    display: flex;
    align-items: center;
    > svg {
      margin-right: 5px;
    }
  }

  input {
    background-color: transparent;
    color: #fff;
    border: none;
    padding: 5px 0 10px;
    flex: 1;
    appearance: none;
    -moz-appearance: textfield;
    &:focus {
      outline: none;
    }
  }
`;
