import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Layout from '../Layout';
import * as screens from '../../screens';
import Title from '../Title';

const AdminRouter = () => (
  <Layout>
    <Switch>
      <Route exact path="/estoque" component={screens.Inventory} />
      <Route exact path="/compras" component={screens.StockEntry} />
      <Route exact path="/eventos" component={screens.Events} />
      <Route path="/" component={() => <Title>Dashboard</Title>} />
    </Switch>
  </Layout>
);

export default AdminRouter;
