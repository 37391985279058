import styled from 'styled-components';
import { darken } from 'polished';
import { ifProp, prop } from 'styled-tools';

export const Container = styled.button`
  width: ${ifProp('width', `${prop('width')}px`, 'auto')};
  padding: 10px 20px;
  border-radius: 6px;
  background-color: ${props =>
    props.theme.colors[props.color] || props.theme.colors.primary};
  transition: 0.2s;
  display: flex;

  &:hover {
    background-color: ${props =>
      darken(
        0.2,
        `${props.theme.colors[props.color] || props.theme.colors.primary}`
      )};
  }

  > *:not(:last-child) {
    margin-right: 5px;
  }

  &:disabled {
    opacity: 0.7;
  }
`;
