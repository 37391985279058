import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useResource from '../../resource';
import Title from '../Title';
import Input from '../Input';
import TextArea from '../TextArea';
import SwitchLabel from '../SwitchLabel';
import Modal from '../Modal';
import StockItemForm from '../StockItemForm';
import Button from '../Button';
import { Center, Form } from './styles';

const StockItemDetails = ({ product, onSubmit, onClose }) => {
  const resource = useResource('inputLog', {
    autoFetch: false,
    updateSchema: 'inputs',
  });
  const [showStockExit, setShowStockExit] = useState(false);
  const [formData, setFormData] = useState({
    idInput: product.id,
    amount: '',
    notes: '',
  });

  return (
    <Modal onClose={onClose}>
      <Title size={18}>Produto</Title>
      <Center>
        <SwitchLabel
          firstOption="Detalhes"
          secondOption="Saída"
          checked={showStockExit}
          onClick={() => setShowStockExit(!showStockExit)}
        />
      </Center>
      {!showStockExit ? (
        <StockItemForm product={product} onSubmit={onSubmit} />
      ) : (
        <Form
          onSubmit={e => {
            e.preventDefault();
            resource.create({
              data: formData,
              idSchema: product.id,
              onResponse: res =>
                res.status === 200 &&
                setFormData({
                  idInput: product.id,
                  amount: '',
                  notes: '',
                }),
            });
          }}
        >
          <Input
            label="Quantidade de saída"
            name="exit"
            value={formData.amount}
            onChange={amount => setFormData({ ...formData, amount })}
          />
          <TextArea
            label="Observações"
            name="message"
            value={formData.notes}
            onChange={notes => setFormData({ ...formData, notes })}
          />
          <Button
            type="submit"
            width="100%"
            text="Salvar"
            style={{ marginTop: 'auto' }}
          />
        </Form>
      )}
    </Modal>
  );
};

StockItemDetails.propTypes = {
  product: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default StockItemDetails;
