import React, { useState, useEffect } from 'react';
import { useCollapseMenuContext } from '../../contexts/CollapseMenuContext';
import Icon from '../Icon';
import { Container, Menu, MenuLink, MenuIcon, Collapse } from './styles';
import routes from '../../config/routes';

const Sidebar = () => {
  const { isCollapsed, setIsCollapsed } = useCollapseMenuContext();
  const [showText, setShowText] = useState(isCollapsed);

  useEffect(() => {
    if (!isCollapsed) {
      setTimeout(() => setShowText(!showText), 205);
    } else {
      setShowText(false);
    }
    // eslint-disable-next-line
  }, [isCollapsed]);

  return (
    <Container collapsed={isCollapsed}>
      <Collapse onClick={() => setIsCollapsed(!isCollapsed)}>
        <Icon icon="menu" size={20} />
      </Collapse>
      <Menu>
        {routes.map(row => (
          <MenuLink to={row.route} key={row.route}>
            <MenuIcon>
              <Icon icon={row.icon} color="white" size={20} />
            </MenuIcon>
            {showText && <span>{row.name}</span>}
          </MenuLink>
        ))}
      </Menu>
    </Container>
  );
};

export default Sidebar;
