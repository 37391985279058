import React, { useRef } from 'react';
import { useCollapseMenuContext } from '../../contexts/CollapseMenuContext';
import usePerfectScrollbar from '../../hooks/usePerfectScrollbar';
import Sidebar from '../Sidebar';
import { View } from './styles';

const Layout = ({ children }) => {
  const container = useRef();
  usePerfectScrollbar(container, true, true, true);
  const { isCollapsed } = useCollapseMenuContext();

  return (
    <>
      <Sidebar />
      <View ref={container} collapsed={isCollapsed}>
        {children}
      </View>
    </>
  );
};

export default Layout;
