import React, { useState } from 'react';
import moment from 'moment';
import { Case } from 'react-case-when';
import Grid from '../../components/Grid';
import Title from '../../components/Title';
import { SubMenu, Link } from '../../components/SubMenu';
import Input from '../../components/Input';
import Button from '../../components/Button';
import CardEvent from '../../components/CardEvent';
import DataTable from '../../components/DataTable';
import tecnicoperfeito from '../../assets/images/tecnico-perfeito.jpg';
import dgtl from '../../assets/images/dgtl.png';
import { Cards } from './styles';

const Events = () => {
  const [listType, setListType] = useState('cards');

  const data = [
    {
      id: 1,
      name: 'Terraza presents: O tal do Bicho Piruleta',
      eventDate: new Date(),
      image: tecnicoperfeito,
    },
    {
      id: 2,
      name: 'Terraza presents: O tal do Bicho Piruleta',
      eventDate: new Date(),
      image: dgtl,
    },
  ];

  const columns = [
    {
      Header: 'Event',
      accessor: 'name',
      style: { padding: 0 },

      Cell: ({ row }) => (
        <Grid marginBetweenItems={30} alignItems="center">
          <img
            src={row.image}
            alt={row.name}
            style={{ height: 52, borderRadius: '6px 0 0 6px' }}
          />
          <span style={{ fontSize: 16, fontWeight: 300 }}>{row.name}</span>
        </Grid>
      ),
    },
    {
      Header: 'Data',
      accessor: 'eventDate',
      align: 'left',
      width: 300,
      Cell: ({ row }) => (
        <span style={{ lineHeight: '52px', fontWeight: 300 }}>
          {moment(row.eventDate).format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      Header: 'Horário',
      accessor: 'eventDate',
      align: 'left',
      width: 300,
      Cell: ({ row }) => (
        <span style={{ lineHeight: '52px', fontWeight: 300 }}>
          {moment(row.eventDate).format('HH:mm')}
        </span>
      ),
    },
  ];

  return (
    <Grid direction="column" marginBetweenItems={40}>
      <Grid justifyContent="space-between">
        <Title>Eventos</Title>
        <SubMenu>
          <Link to="/inventory">Próximos</Link>
          <Link to="/inventory">Anteriores</Link>
          <Link to="/">Todos</Link>
        </SubMenu>
      </Grid>
      <Grid justifyContent="space-between">
        <Input
          icon="faSearch"
          name="search"
          placeholder="Pesquise por item"
          required={false}
          onChange={() => {}}
          width={300}
        />
        <Grid style={{ width: 'auto' }} marginBetweenItems={20}>
          <Grid style={{ width: 'auto' }} marginBetweenItems={12}>
            <Button
              color={listType === 'cards' ? 'grayDark' : 'gray'}
              icon="cards-list"
              style={{ padding: '10px 14px' }}
              onClick={() => setListType('cards')}
            />
            <Button
              color={listType === 'table' ? 'grayDark' : 'gray'}
              icon="row-list"
              style={{ padding: '10px 14px' }}
              onClick={() => setListType('table')}
            />
          </Grid>
          <Button icon="faPlus" text="Novo" />
        </Grid>
      </Grid>
      <Case when={listType === 'cards'}>
        <Cards>
          <CardEvent />
          <CardEvent />
          <CardEvent />
          <CardEvent />
          <CardEvent />
          <CardEvent />
          <CardEvent />
        </Cards>
      </Case>
      <Case when={listType === 'table'}>
        <DataTable selectable data={data} columns={columns} rowHeight={52} />
      </Case>
    </Grid>
  );
};

export default Events;
