import styled from 'styled-components';

export default styled.div`
  height: 225px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  box-shadow: -10px 9px 20px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
  position: relative;
  cursor: pointer;

  &:hover {
    box-shadow: -10px 9px 20px rgba(0, 0, 0, 0.45);
    transform: translateY(-4px);
    > img {
      transform: scale(1.04);
    }
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform 0.2s;
  }

  > div {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(63, 76, 91, 0.4);
    backdrop-filter: blur(10px);
    display: flex;
    width: 100%;
    padding: 12px;
  }

  span {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
  }

  p {
    margin: 0;
    line-height: 1;
    font-size: 13px;
  }

  h2 {
    font-size: 15px;
    font-weight: 400;
    margin: 0 0 0 12px;
  }
`;
