import updateObj from 'immutability-helper';
import axios from 'axios';
import { normaliz } from 'normaliz';
import { toast } from 'react-toastify';
import createActionTypes from './createActionTypes';
import createReducer from './createReducer';
import api from './api';
import * as schemas from './schemas';
import { API_URL } from '../config/constants';

/**
 *  Action Types
 */

export const actionTypes = createActionTypes('RESOURCES', [
  'REGISTER_RESOURCE',

  'RESET',

  'FETCH_ALL',
  'FETCH_ALL_PENDING',
  'FETCH_ALL_FULFILLED',
  'FETCH_ALL_REJECTED',

  'FETCH_ONE',
  'FETCH_ONE_PENDING',
  'FETCH_ONE_FULFILLED',
  'FETCH_ONE_REJECTED',

  'CREATE',
  'CREATE_PENDING',
  'CREATE_FULFILLED',
  'CREATE_REJECTED',

  'UPDATE',
  'UPDATE_PENDING',
  'UPDATE_FULFILLED',
  'UPDATE_REJECTED',

  'REMOVE',
  'REMOVE_PENDING',
  'REMOVE_FULFILLED',
  'REMOVE_REJECTED',
]);

/**
 * Initial State
 */

const resourceState = {
  data: {},
  original: [],
  meta: {},
  isFetching: false,
  isRemoving: false,
  isSubmitting: false,
  error: {},
  selected: null,
  createdRecord: null,
  pagination: false,
  page: 0,
  perPage: 10,
};

/**
 * Reducer
 */

export default createReducer(
  {},
  {
    [actionTypes.REGISTER_RESOURCE](
      state,
      { payload: { resource, pagination, perPage } }
    ) {
      const initialState = pagination
        ? { ...resourceState, pagination, perPage }
        : resourceState;
      return updateObj(state, {
        [resource]: { $set: { ...initialState, original: [] } },
      });
    },

    /**
     * Reset.
     */

    [actionTypes.RESET](state, { payload: { resource } }) {
      const initialState = state[resource].pagination
        ? { ...resourceState, pagination: true }
        : resourceState;
      return updateObj(state, {
        [resource]: { $set: { ...initialState, meta: state[resource].meta } },
      });
    },

    /**
     * Fetch All.
     */

    [actionTypes.FETCH_ALL_PENDING](state, { meta: { resource, namespace } }) {
      return updateObj(state, {
        [namespace || resource]: {
          isFetching: { $set: true },
        },
      });
    },

    [actionTypes.FETCH_ALL_FULFILLED](
      state,
      {
        payload: { data, meta },
        meta: { resource, namespace, infiniteScroll, page },
      }
    ) {
      const { original } = state[namespace || resource];
      if (Object.keys(data).length === 0 && !infiniteScroll)
        return updateObj(state, {
          [namespace || resource]: {
            isFetching: { $set: false },
            data: { $set: {} },
            original: { $set: [] },
          },
        });

      return updateObj(state, {
        [namespace || resource]: {
          isFetching: { $set: false },
          data: {
            $set: infiniteScroll
              ? {
                  entities: normaliz(original.concat(data), schemas[resource]),
                  result: original
                    .concat(data)
                    .map(item => item[schemas[resource].keys[resource]]),
                }
              : {
                  entities: normaliz(data, schemas[resource]),
                  result: data.map(
                    item => item[schemas[resource].keys[resource]]
                  ),
                },
          },
          original: infiniteScroll ? { $push: data } : { $set: data },
          meta: { $set: meta || {} },
          error: { $set: {} },
          page: { $set: page },
        },
      });
    },

    [actionTypes.FETCH_ALL_REJECTED](
      state,
      { payload, meta: { resource, namespace } }
    ) {
      return updateObj(state, {
        [namespace || resource]: {
          isFetching: { $set: false },
          error: { $set: payload },
        },
      });
    },

    /**
     * Fetch One.
     */

    [actionTypes.FETCH_ONE_PENDING](
      state,
      { meta: { resource, namespace, id } }
    ) {
      return updateObj(state, {
        [namespace || resource]: {
          isFetching: { $set: true },
          selected: { $set: parseInt(id) },
        },
      });
    },

    [actionTypes.FETCH_ONE_FULFILLED](
      state,
      { payload: { data, meta }, meta: { resource, namespace, id } }
    ) {
      const { original } = state[resource];
      const index = original.findIndex(
        item =>
          item[schemas[resource].keys[resource]] ===
          data[schemas[resource].keys[resource]]
      );

      if (index !== -1) original[index] = data;
      else original.unshift(data);

      return updateObj(state, {
        [namespace || resource]: {
          isFetching: { $set: false },
          data: {
            $set: {
              entities: normaliz(original, schemas[resource]),
              result: original.map(
                item => item[schemas[resource].keys[resource]]
              ),
            },
          },
          original: { $set: original },
          meta: { $set: meta || {} },
          error: { $set: {} },
          selected: { $set: id },
        },
      });
    },

    [actionTypes.FETCH_ONE_REJECTED](state, { meta: { resource, namespace } }) {
      return updateObj(state, {
        [namespace || resource]: {
          isFetching: { $set: false },
          error: { $set: {} },
          selected: { $set: null },
        },
      });
    },

    /**
     * Create.
     */

    [actionTypes.CREATE_PENDING](state, { meta: { resource, namespace } }) {
      return updateObj(state, {
        [namespace || resource]: {
          isSubmitting: { $set: true },
        },
      });
    },

    [actionTypes.CREATE_FULFILLED](
      state,
      { payload: { data }, meta: { resource, namespace, updateSchema } }
    ) {
      const updateResource = updateSchema || namespace || resource;
      const { original } = state[updateResource];
      if (Array.isArray(data)) {
        data.forEach(i => {
          const index = original.findIndex(
            item =>
              item[schemas[updateResource].keys[updateResource]] ===
              i[schemas[updateResource].keys[updateResource]]
          );
          if (index === -1) original.unshift(i);
          else original.splice(index, 1, i);
        });
      } else {
        const index = original.findIndex(
          item =>
            item[schemas[updateResource].keys[updateResource]] ===
            data[schemas[updateResource].keys[updateResource]]
        );
        if (index === -1) original.unshift(data);
        else original.splice(index, 1, data);
      }
      return updateObj(state, {
        [updateResource]: {
          isSubmitting: { $set: false },
          data: {
            $set: {
              entities: normaliz(original, schemas[updateResource]),
              result: original.map(
                item => item[schemas[updateResource].keys[updateResource]]
              ),
            },
          },
          original: { $set: original },
          createdRecord: {
            $set: data[schemas[updateResource].keys[updateResource]],
          },
          error: { $set: {} },
        },
      });
    },

    [actionTypes.CREATE_REJECTED](
      state,
      { payload, meta: { resource, namespace } }
    ) {
      return updateObj(state, {
        [namespace || resource]: {
          isSubmitting: { $set: false },
          error: { $set: payload },
        },
      });
    },

    /**
     * Update.
     */

    [actionTypes.UPDATE_PENDING](state, { meta: { resource, namespace } }) {
      return updateObj(state, {
        [namespace || resource]: {
          isSubmitting: { $set: true },
        },
      });
    },

    [actionTypes.UPDATE_FULFILLED](
      state,
      { payload: { data }, meta: { resource, namespace, id, updateSchema } }
    ) {
      const updateResource = updateSchema || namespace || resource;
      const { original } = state[updateResource];
      const index = original.findIndex(
        item => item[schemas[updateResource].keys[updateResource]] === id
      );
      original.splice(index, 1, data);
      return updateObj(state, {
        [updateResource]: {
          isSubmitting: { $set: false },
          data: {
            $set: {
              entities: normaliz(original, schemas[updateResource]),
              result: original.map(
                item => item[schemas[updateResource].keys[updateResource]]
              ),
            },
          },
          original: { $set: original },
          error: { $set: {} },
        },
      });
    },

    [actionTypes.UPDATE_REJECTED](
      state,
      { payload, meta: { resource, namespace } }
    ) {
      return updateObj(state, {
        [namespace || resource]: {
          isSubmitting: { $set: false },
          error: { $set: payload },
        },
      });
    },

    /**
     * Remove.
     */

    [actionTypes.REMOVE_PENDING](state, { meta: { resource, namespace } }) {
      return updateObj(state, {
        [namespace || resource]: {
          isRemoving: { $set: true },
        },
      });
    },

    [actionTypes.REMOVE_FULFILLED](
      state,
      {
        payload: { data } = {},
        meta: { resource, namespace, id, updateSchema },
      }
    ) {
      const updateResource = updateSchema || namespace || resource;
      const idToCompare = updateSchema
        ? data[schemas[updateResource].keys[updateResource]]
        : id;
      const { original } = state[updateResource];
      if (Array.isArray(idToCompare)) {
        idToCompare.forEach(currId => {
          const index = original.findIndex(
            item =>
              item[schemas[updateResource].keys[updateResource]] === currId
          );
          original.splice(index, 1);
        });
      } else {
        const index = original.findIndex(
          item =>
            item[schemas[updateResource].keys[updateResource]] === idToCompare
        );

        if (data) original.splice(index, 1, data);
        else original.splice(index, 1);
      }

      return updateObj(state, {
        [updateResource]: {
          data: {
            $set: Array.isArray(data)
              ? {
                  entities: normaliz(data, schemas[updateResource]),
                  result: data.map(
                    item => item[schemas[updateResource].keys[updateResource]]
                  ),
                }
              : {
                  entities: normaliz(original, schemas[updateResource]),
                  result: original.map(
                    item => item[schemas[updateResource].keys[updateResource]]
                  ),
                },
          },
          original: { $set: Array.isArray(data) ? data : original },
          isRemoving: { $set: false },
          error: { $set: {} },
        },
      });
    },

    [actionTypes.REMOVE_REJECTED](
      state,
      { payload, meta: { resource, namespace } }
    ) {
      return updateObj(state, {
        [namespace || resource]: {
          isRemoving: { $set: false },
          error: { $set: payload },
        },
      });
    },
  }
);

/**
 * Action Creators.
 */

export const registerResource = (resource, namespace, pagination, perPage) => (
  dispatch,
  getState
) => {
  const state = getState();
  const resourceToRegister = namespace || resource;
  if (Object.prototype.hasOwnProperty.call(state, resourceToRegister)) return;
  return dispatch({
    type: actionTypes.REGISTER_RESOURCE,
    payload: { resource: resourceToRegister, pagination, perPage },
  });
};

export const reset = (resource, namespace) => dispatch => {
  const resourceToReset = namespace || resource;
  return dispatch({
    type: actionTypes.RESET,
    payload: { resource: resourceToReset },
  });
};

export const fetchAll = (
  resource,
  namespace,
  params,
  infiniteScroll,
  onResponse,
  preventReset
) => dispatch => {
  if (!preventReset) {
    dispatch(reset(resource, namespace));
  }

  const keys = Object.keys(params);
  const url = keys.reduce((acc, curr) => {
    if (acc.includes(curr)) return acc.replace(`:${curr}`, params[curr]);
    return acc;
  }, api[resource].get);

  return dispatch({
    type: actionTypes.FETCH_ALL,
    payload: new Promise((resolve, reject) => {
      axios
        .get(API_URL + url, { params })
        .then(response => {
          if (onResponse) onResponse(response);
          resolve(response.data);
        })
        .catch(error => {
          if (onResponse) onResponse(error);
          toast.error(error?.response?.data?.err?.message);
          reject(error);
        });
    }),
    meta: {
      resource,
      namespace,
      infiniteScroll,
      page: params?.page ?? 0,
    },
  });
};

export const fetchOne = (
  resource,
  id,
  params,
  onResponse,
  namespace
) => dispatch => {
  const keys = Object.keys(params);
  const url = keys.reduce((acc, curr) => {
    if (acc.includes(curr)) return acc.replace(`:${curr}`, params[curr]);
    return acc;
  }, api[resource].get);

  return dispatch({
    type: actionTypes.FETCH_ONE,
    payload: new Promise((resolve, reject) => {
      axios
        .get(`${API_URL + url}/${id}`, { params })
        .then(response => {
          if (onResponse) onResponse(response);
          resolve(response.data);
        })
        .catch(error => {
          if (onResponse) onResponse(error);
          toast.error(error?.response?.data?.err?.message);
          reject(error);
        });
    }),
    meta: {
      resource,
      namespace,
      id,
    },
  });
};

export const create = (
  resource,
  namespace,
  data,
  updateSchema,
  params,
  onResponse
) => dispatch => {
  const keys = Object.keys(params);
  const url = keys.reduce((acc, curr) => {
    if (acc.includes(curr)) return acc.replace(`:${curr}`, params[curr]);
    return acc;
  }, api[resource].post);

  return dispatch({
    type: actionTypes.CREATE,
    payload: new Promise((resolve, reject) => {
      axios
        .post(API_URL + url, data)
        .then(response => {
          if (onResponse) onResponse(response);
          if (response?.data?.meta?.message)
            toast.success(response.data.meta.message);
          resolve(response.data);
        })
        .catch(error => {
          if (onResponse) onResponse(error);
          toast.error(error?.response?.data?.err?.message);
          reject(error);
        });
    }),
    meta: {
      resource,
      namespace,
      updateSchema,
    },
  });
};

export const update = (
  resource,
  namespace,
  id,
  data,
  updateSchema,
  idSchema,
  params,
  onResponse
) => dispatch => {
  const keys = Object.keys(params);
  const url = keys.reduce((acc, curr) => {
    if (acc.includes(curr)) return acc.replace(`:${curr}`, params[curr]);
    return acc;
  }, api[resource].put);

  return dispatch({
    type: actionTypes.UPDATE,
    payload: new Promise((resolve, reject) => {
      axios
        .put(
          API_URL + url.replace(`:${schemas[resource].keys[resource]}`, id),
          data,
          {
            params,
          }
        )
        .then(response => {
          if (onResponse) onResponse(response);
          if (response?.data?.meta?.message)
            toast.success(response.data.meta.message);
          resolve(response.data);
        })
        .catch(error => {
          if (onResponse) onResponse(error);
          toast.error(error?.response?.data?.err?.message);
          reject(error);
        });
    }),
    meta: {
      resource,
      namespace,
      id: idSchema || id,
      updateSchema,
    },
  });
};

export const remove = (
  resource,
  namespace,
  id,
  params,
  updateSchema,
  idSchema,
  onResponse
) => dispatch => {
  const keys = Object.keys(params);
  const url = keys.reduce((acc, curr) => {
    if (acc.includes(curr)) return acc.replace(`:${curr}`, params[curr]);
    return acc;
  }, api[resource].delete);

  return dispatch({
    type: actionTypes.REMOVE,
    payload: new Promise((resolve, reject) => {
      axios
        .delete(
          API_URL + url.replace(`:${schemas[resource].keys[resource]}`, id),
          {
            params,
          }
        )
        .then(response => {
          if (onResponse) onResponse(response);
          if (response?.data?.meta?.message)
            toast.success(response.data.meta.message);
          resolve(response.data);
        })
        .catch(error => {
          if (onResponse) onResponse(error);
          reject(error);
          toast.error(error?.response?.data?.err?.message);
        });
    }),
    meta: {
      resource,
      namespace,
      id: idSchema || id,
      updateSchema,
    },
  });
};
