import styled from 'styled-components';
import { ifProp } from 'styled-tools';

export const View = styled.div`
  position: relative;
  flex: 1;
  height: 100vh;
  overflow: hidden;
  margin-left: ${ifProp('collapsed', '50px', '220px')};
  transition: margin ease-in-out 0.2s;
  padding: 40px 40px 0 40px;
  background: linear-gradient(
    222.64deg,
    #36404b 0%,
    #323c46 52.04%,
    #21272f 100%
  );

  @media (max-width: 1280px) {
    padding: 20px 20px 0 20px;
  }
`;
