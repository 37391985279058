import React, { useState, useContext, createContext } from 'react';

const CollapseMenuContext = createContext();

export const useCollapseMenuContext = () => useContext(CollapseMenuContext);

export default function CollapseMenuProvider({ children }) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <CollapseMenuContext.Provider value={{ isCollapsed, setIsCollapsed }}>
      {children}
    </CollapseMenuContext.Provider>
  );
}
