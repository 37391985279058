import React from 'react';
import { useStockEntryContext } from '../../contexts/StockEntryContext';
import useResource from '../../resource';
import Grid from '../Grid';
import Title from '../Title';
import Input from '../Input';
import Select from '../Select';
import Icon from '../Icon';
import { Container, Row, ButtonIcon, Counter } from './styles';

const StockEntryItems = () => {
  const { formData, setFormData } = useStockEntryContext();
  const inputs = useResource('inputs');

  return (
    <Grid direction="column" marginBetweenItems={20}>
      <Title size={12} style={{ textTransform: 'uppercase', opacity: 0.8 }}>
        Itens
      </Title>
      <Container>
        {formData.items.map((item, index) => (
          <Row key={index}>
            <Grid marginBetweenItems={20} alignItems="center">
              <div style={{ width: '170%' }}>
                <Select
                  label="Item"
                  name="items[].idInput"
                  value={item.idInput}
                  onChange={value => {
                    formData.items[index].idInput = value;
                    setFormData({ ...formData });
                  }}
                >
                  {inputs?.data?.result?.map(id => {
                    const row = inputs.data.entities.inputs[id];
                    return (
                      <option key={id} value={row.idInput}>
                        {row.name}
                      </option>
                    );
                  })}
                </Select>
              </div>
              <Input
                label="Quantidade"
                type="number"
                name="items[].amount"
                value={item.amount}
                min={0}
                onChange={value => {
                  formData.items[index].amount = value;
                  setFormData({ ...formData });
                }}
              />
              <Input
                label="Preço unitário"
                name="items[].price"
                value={item.price}
                onChange={value => {
                  formData.items[index].price = value;
                  setFormData({ ...formData });
                }}
              />
              <div style={{ width: 20 }}>
                <ButtonIcon
                  type="button"
                  onClick={() => {
                    formData.items.splice(index, 1);
                    setFormData({ ...formData });
                  }}
                >
                  <Icon
                    icon="faMinus"
                    style={{ fontSize: 10, marginBottom: 1 }}
                  />
                </ButtonIcon>
              </div>
            </Grid>
          </Row>
        ))}
        <Grid
          marginBetweenItems={20}
          justifyContent="flex-end"
          style={{ marginTop: 20, paddingRight: 20 }}
        >
          <Counter>Valor Total: R$ 5.000,00</Counter>
          <ButtonIcon
            add
            type="button"
            onClick={() => {
              formData.items.push({ idInput: '', amount: '', price: '' });
              setFormData({ ...formData });
            }}
          >
            <Icon icon="faPlus" style={{ fontSize: 10, marginBottom: 1 }} />
          </ButtonIcon>
        </Grid>
      </Container>
    </Grid>
  );
};

export default StockEntryItems;
