import React from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import Container from './styles';

const Radio = ({ label, name, value, optionValue, onClick, required }) => {
  const id = nanoid();
  return (
    <Container>
      <input
        id={id}
        type="radio"
        name={name}
        value={optionValue}
        checked={optionValue === value}
        required={required}
        onClick={e => {
          console.log('click');
          onClick(e.target.value);
        }}
        onChange={() => {}}
      />
      <label htmlFor={id}>{label}</label>
    </Container>
  );
};

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  optionValue: PropTypes.any.isRequired,
  required: PropTypes.any,
  onClick: PropTypes.func.isRequired,
};

Radio.defaultProps = {
  required: true,
};

export default Radio;
