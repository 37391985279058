import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import resources from '../resource/resources';

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

const reducer = combineReducers({
  resources,
});

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk), applyMiddleware(promise))
);

export default store;
