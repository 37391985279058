import React, { useState, useEffect } from 'react';
import { Case } from 'react-case-when';
import useResource from '../../resource';
import Title from '../../components/Title';
import DataTable from '../../components/DataTable';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import StockItemDetails from '../../components/StockItemDetails';
import StockItemForm from '../../components/StockItemForm';
import InventoryFilter from '../../components/InventoryFilter';
import Loading from '../../components/Loading';
import { SubMenu, Link } from '../../components/SubMenu';
import { Top, Search, Amount, Grid, Left, Buttons, Empty } from './styles';
import empty from '../../assets/images/empty.svg';

const Inventory = () => {
  const resource = useResource('inputs', {
    infiniteScroll: true,
    preventReset: true,
  });
  const [showAdd, setShowAdd] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(resource?.isFetching ?? true);
  const [idModal, setIdModal] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const selectedRow =
    resource?.original?.filter(row => row.id === idModal)[0] ?? {};

  useEffect(() => {
    if (resource.original && !resource.isFetching) {
      setFilteredData(resource.original);
      setIsLoading(false);
    }
  }, [resource]);

  const columns = [
    { Header: 'Nome', accessor: 'name' },
    { Header: 'Categoria', accessor: 'category.name', width: '200px' },
    {
      Header: 'Qtd',
      accessor: 'amount',
      width: '100px',
      Cell: ({ row }) => (
        <Amount
          size={row.size}
          amount={row.amount}
          minimumAmount={row.minimumAmount}
        >
          {row.amount / row.size}
        </Amount>
      ),
    },
  ];

  return (
    <Grid>
      <Left>
        <Top>
          <Title>Estoque</Title>
          <SubMenu>
            <Link to="/inventory">Bebidas</Link>
            <Link to="/inventory">Merchant</Link>
            <Link to="/">Todos</Link>
          </SubMenu>
        </Top>
        <Search>
          <Input
            width={300}
            icon="faSearch"
            name="search"
            placeholder="Pesquise por item"
            required={false}
            onChange={search => {
              setIsLoading(true);
              setShowDetails(false);
              resource.reset();
              resource.fetchAll({
                fetchParams: { search },
                page: 1,
                onResponse: res => res.status === 200 && setIsLoading(false),
              });
            }}
          />
          <Buttons>
            {showFilter && (
              <InventoryFilter
                handleClose={() => setShowFilter(false)}
                onFilter={filters => {
                  setIsLoading(true);
                  resource.reset();
                  resource.fetchAll({
                    fetchParams: { ...filters },
                    page: 1,
                    onResponse: res =>
                      res.status === 200 && setIsLoading(false),
                  });
                }}
              />
            )}
            <Button
              color="gray"
              text="Filtrar"
              icon="filter"
              onClick={() => setShowFilter(!showFilter)}
            />
            <Button
              text="Novo"
              icon="plus"
              onClick={() => {
                setShowDetails(false);
                setShowAdd(true);
              }}
            />
          </Buttons>
        </Search>
        {filteredData.length > 0 ? (
          <DataTable
            selected={selectedRow.id}
            selectable
            data={filteredData}
            columns={columns}
            onPageChange={() => resource.fetchAll()}
            onClick={row => {
              setIdModal(row.id);
              setShowAdd(false);
              setShowDetails(true);
            }}
          />
        ) : (
          <>
            <Case when={isLoading}>
              <Loading />
            </Case>
            <Case when={!isLoading}>
              <Empty>
                <img src={empty} alt="Nenhum registro encontrado." />
                <h3>Oops!</h3>
                <span>Sua busca não corresponde a nenhum resultado.</span>
              </Empty>
            </Case>
          </>
        )}
      </Left>
      {showDetails && (
        <StockItemDetails
          product={selectedRow}
          onClose={() => {
            setIdModal(null);
            setShowDetails(false);
          }}
          onSubmit={data => resource.update({ data, idUpdate: selectedRow.id })}
        />
      )}
      {showAdd && (
        <Modal onClose={() => setShowAdd(false)}>
          <Title size={18}>Novo Item</Title>
          <StockItemForm onSubmit={data => resource.create({ data })} />
        </Modal>
      )}
    </Grid>
  );
};

export default Inventory;
