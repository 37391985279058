import { createGlobalStyle } from 'styled-components';
import { theme } from 'styled-tools';

export default createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    color: #fff;
  }

  body {
    font-family: ${theme('fonts.primary')};
    margin: 0;
    padding: 0;
    background: ${theme('colors.background')};
    height: 100vh;
  }

  button {
    margin: 0;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    &:focus {
      outline: 0;
    }
  }

  .toast-container {
    left: 85px;
    @media (max-width: 1280px) {
      left: 70px;
    }
  }

  .Toastify__toast {
    border-radius: 4px;
    font-size: 14px;
  }

  .Toastify__toast--error {
    background: #EC5555;
  }
  
  .Toastify__toast--success {
    background: #59C571;
  }
`;
