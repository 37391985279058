import styled from 'styled-components';

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

export const Search = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Grid = styled.div`
  display: flex;
  height: 100%;
`;

export const Left = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Amount = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    content: '';
    width: 3px;
    height: 70%;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: ${props =>
      // eslint-disable-next-line no-nested-ternary
      props.amount / props.size < props.minimumAmount
        ? props.theme.colors.danger
        : props.amount / props.size < props.minimumAmount + 20
        ? props.theme.colors.warning
        : props.theme.colors.success};
    box-shadow: ${props =>
      props.amount > 200
        ? '0px 0px 8px rgba(141, 222, 159, 0.4)'
        : ' 0px 0px 8px rgba(231, 116, 116, 0.4)'};
  }
`;

export const Buttons = styled.div`
  display: flex;
  position: relative;
  z-index: 2;

  > *:not(:last-child) {
    margin-right: 20px;
  }

  > div {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    margin: 0 !important;
    z-index: 1;
  }
`;

export const Empty = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10%;
  h3 {
    font-size: 30px;
    margin: 0 0 10px;
    font-weight: 300;
  }
  span {
    font-size: 14px;
    font-weight: 300;
  }
  > img {
    width: 170px;
  }
`;
