import { useEffect } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';

export default function usePerfectScrollbar(
  scrollContainerRef,
  scrollActive,
  wheelPropagation,
  suppressScrollX,
  listener,
  disableClickScroll,
  depsArray
) {
  useEffect(
    () => {
      if (scrollActive) {
        const perfectScrollbarConfig = {
          wheelPropagation,
          suppressScrollX,
        };
        if (disableClickScroll) {
          perfectScrollbarConfig.handlers = ['wheel', 'touch'];
        }

        let ps = new PerfectScrollbar(
          scrollContainerRef.current,
          perfectScrollbarConfig
        );

        if (listener) listener();

        return () => {
          ps.destroy();
          ps = null;
        };
      }
    },
    // eslint-disable-next-line
    Array.isArray(depsArray) ? depsArray : []
  );
}
