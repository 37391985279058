/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, memo, useRef } from 'react';
import PropTypes from 'prop-types';
import objectPath from 'object-path';
import { Styles } from './styles';
import usePerfectScrollbar from '../../hooks/usePerfectScrollbar';

const HeaderCell = ({ header, width, align }) => {
  const Header = header;

  return (
    <div
      role="columnheader"
      style={{
        width,
        flex: `${width} 0 auto}`,
        flexGrow: `${width ? 0 : 1}`,
        maxWidth: width,
        textAlign: align || 'left',
      }}
    >
      {typeof Header === 'string' ? (
        <div style={{ position: 'relative' }}>{header}</div>
      ) : (
        <Header />
      )}
    </div>
  );
};

HeaderCell.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

HeaderCell.defaultProps = {
  width: undefined,
};

const Table = ({
  data,
  columns,
  onPageChange,
  selectable,
  selected,
  onClick,
  rowHeight,
}) => {
  const container = useRef();
  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);

  const scrollListener = () =>
    container?.current?.addEventListener('ps-y-reach-end', () => {
      onPageChange();
    });
  usePerfectScrollbar(container, true, true, true, scrollListener);

  return (
    <Styles selectable={selectable}>
      <div role="grid">
        <div className="header">
          <div role="row">
            {memoizedColumns.map(
              (
                {
                  Header,
                  accessor,
                  width,
                  filterable,
                  filterType,
                  show = true,
                  align,
                },
                i
              ) =>
                show && (
                  <HeaderCell
                    key={i}
                    header={Header}
                    accessor={accessor}
                    width={width}
                    filterable={filterable}
                    filterType={filterType}
                    align={align}
                  />
                )
            )}
          </div>
        </div>
        <div ref={container} className="body">
          <div role="rowgroup">
            {memoizedData.length &&
              memoizedData.map((row, i) => (
                <div
                  key={i}
                  role="row"
                  onClick={() => onClick(row)}
                  style={
                    selected === row.id
                      ? { backgroundColor: '#627488', height: rowHeight }
                      : { height: rowHeight }
                  }
                >
                  {memoizedColumns.map(
                    (
                      { accessor, width, show = true, align, Cell, style },
                      id
                    ) =>
                      show && (
                        <div
                          key={id}
                          role="cell"
                          style={{
                            width,
                            flex: `${width} 0 auto`,
                            flexGrow: `${width ? 0 : 1}`,
                            maxWidth: width !== 150 ? width : 'auto',
                            textAlign: align || 'left',
                            ...style,
                          }}
                        >
                          {Cell ? (
                            <Cell row={row} index={i} />
                          ) : (
                            objectPath.get(row, accessor)
                          )}
                        </div>
                      )
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </Styles>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

export default memo(Table);
