import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import './icons.css';

const Container = styled.span`
  color: ${props =>
    props.color in props.theme.colors
      ? props.theme.colors[props.color]
      : props.color};
  ${props => props.size && `font-size: ${props.size}px;`}
  display: flex;
  align-items: center;
`;

const IconStyled = styled.span`
  line-height: 0;
  color: inherit;
  font-size: inherit;
`;

export default function Icon({ icon, color, size, ...props }) {
  return (
    <Container color={color} size={size}>
      <IconStyled className={`icon-${icon}`} />
    </Container>
  );
}

Icon.propTypes = {
  /** Ícone */
  icon: PropTypes.string.isRequired,

  /** Cor do ícone */
  color: PropTypes.string,

  /** Tamanho do ícone */
  size: PropTypes.number,
};
