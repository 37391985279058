import React from 'react';
import Helmet from 'react-helmet';
import Wrapper from '../Wrapper';
import AdminRouter from '../../components/AdminRouter';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

const App = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Aplicatinho</title>
      <link
        href="https://fonts.googleapis.com/css?family=Lato:300,400,500,700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <Wrapper>
      <AdminRouter />
    </Wrapper>
  </>
);

export default App;
