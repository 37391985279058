import styled from 'styled-components';
import { prop } from 'styled-tools';

export default styled.div`
  width: ${prop('width')};
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 65%;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #fff transparent transparent transparent;
    transform: translateY(-50%);
  }

  label {
    font-size: 12px;
  }

  > div {
    display: flex;
    align-items: center;
    > svg {
      margin-right: 5px;
    }
  }

  select {
    background-color: transparent;
    color: #fff;
    border: none;
    padding: 5px 0 8px;
    width: 100%;
    appearance: none;
    &:invalid {
      opacity: 0.7;
    }
  }

  &:focus {
    outline: none;
  }
`;
