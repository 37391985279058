import React from 'react';
import { StyledTitle } from './styles';

const Title = ({ size, style, children }) => (
  <StyledTitle size={size} style={style}>
    {children}
  </StyledTitle>
);

export default Title;
