/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import Container from './styles';

const Select = ({
  children,
  label,
  name,
  placeholder,
  value,
  onChange,
  width,
  required,
}) => (
  <Container width={width}>
    {label && <label htmlFor={`field-${name}`}>{label}</label>}
    <select
      id={`field-${name}`}
      name={name}
      value={value}
      onChange={e => onChange(e.target.value)}
      required={required}
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {children}
    </select>
  </Container>
);

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  value: PropTypes.any,
  children: PropTypes.any,
  required: PropTypes.bool,
};

Select.defaultProps = {
  width: '100%',
  placeholder: 'Escolha uma opção',
  required: true,
};

export default Select;
