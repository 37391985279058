import React, { useState, useRef, useEffect, useCallback } from 'react';
import useResource from '../../resource';
import Select from '../Select';
import Button from '../Button';
import Grid from '../Grid';
import { RadioGroup, Radio } from '../Radio';
import Container from './styles';

const InventoryFilter = ({ onFilter, handleClose }) => {
  const [filters, setFilters] = useState({ idCategory: '', status: '' });
  const ref = useRef(null);
  const resource = useResource('inputCategories');

  const handleClickOutside = useCallback(
    event => {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClose();
      }
    },
    [ref, handleClose]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handleClickOutside]);

  return (
    <Container ref={ref}>
      <Select
        name="category"
        label="Categoria"
        value={filters.idCategory}
        onChange={value => setFilters({ ...filters, idCategory: value })}
      >
        {resource?.data?.result?.map(id => {
          const category = resource.data.entities.inputCategories[id];
          return (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          );
        })}
      </Select>
      <RadioGroup>
        <Radio
          label="Status A"
          value={filters.status}
          optionValue="a"
          name="status"
          onClick={value => setFilters({ ...filters, status: value })}
        />
        <Radio
          label="Status B"
          value={filters.status}
          optionValue="b"
          name="status"
          onClick={value => setFilters({ ...filters, status: value })}
        />
        <Radio
          label="Status C"
          value={filters.status}
          optionValue="c"
          name="status"
          onClick={value => setFilters({ ...filters, status: value })}
        />
      </RadioGroup>
      <Grid marginBetweenItems={20}>
        <Button
          color="gray"
          text="Limpar"
          style={{ flex: 1 }}
          onClick={() => {
            setFilters({ idCategory: '', status: '' });
            onFilter({});
          }}
        />
        <Button
          text="Filtrar"
          style={{ flex: 1 }}
          onClick={() => onFilter(filters)}
        />
      </Grid>
    </Container>
  );
};

export default InventoryFilter;
