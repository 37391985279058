import React, { useState, useEffect } from 'react';
import useResource from '../../resource';
import Grid from '../Grid';
import Select from '../Select';
import Input from '../Input';
import Button from '../Button';

const StockItemForm = ({ product, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: product?.name ?? '',
    idCategory: product?.idCategory ?? '',
    idType: product?.idType ?? '',
    idClient: product?.idClient ?? '',
    size: product?.size ?? '',
    measure: product?.measure ?? '',
    amount: product?.amount ?? '',
    minimumAmount: product?.minimumAmount,
  });

  useEffect(() => {
    const initialState = {
      name: product?.name ?? '',
      idCategory: product?.idCategory ?? '',
      idType: product?.idType ?? '',
      idClient: product?.idClient ?? '',
      size: product?.size ?? '',
      measure: product?.measure ?? '',
      amount: product?.amount ?? '',
      minimumAmount: product?.minimumAmount,
    };

    setFormData(initialState);
  }, [product]);

  const types = useResource('inputTypes');
  const categories = useResource('inputCategories');

  return (
    <form
      style={{ height: '100%' }}
      onSubmit={e => {
        e.preventDefault();
        onSubmit(formData);
      }}
    >
      <Grid
        direction="column"
        marginBetweenItems={20}
        style={{ height: '100%' }}
      >
        <Input
          name="name"
          label="Nome"
          value={formData.name}
          onChange={name => setFormData({ ...formData, name })}
        />
        <Select
          name="type"
          label="Tipo"
          value={formData.idType}
          onChange={idType =>
            setFormData({ ...formData, idType: parseInt(idType) })
          }
        >
          {types?.data?.result?.map(id => {
            const type = types.data.entities.inputTypes[id];
            return (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            );
          })}
        </Select>
        <Select
          name="category"
          label="Categoria"
          value={formData.idCategory}
          onChange={idCategory =>
            setFormData({ ...formData, idCategory: parseInt(idCategory) })
          }
        >
          {categories?.data?.result?.map(id => {
            const category = categories.data.entities.inputCategories[id];
            return (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            );
          })}
        </Select>
        <Input
          name="size"
          label="Quantidade por unidade"
          width={100}
          value={formData.size}
          onChange={size => setFormData({ ...formData, size: parseInt(size) })}
        />
        <Select
          name="measure"
          label="Unidade de medida"
          placeholder="Escolha"
          value={formData.measure}
          onChange={measure => setFormData({ ...formData, measure })}
        >
          <option value="ml">ml</option>
          <option value="kg">kg</option>
        </Select>
        {!product && (
          <Input
            name="amount"
            label="Quantidade inicial em estoque"
            width={100}
            value={formData.amount}
            onChange={amount =>
              setFormData({ ...formData, amount: parseInt(amount) })
            }
          />
        )}
        <Input
          name="amount"
          label="Quantidade mínima ideal em estoque"
          width={100}
          value={formData.minimumAmount}
          onChange={minimumAmount =>
            setFormData({
              ...formData,
              minimumAmount: parseInt(minimumAmount),
            })
          }
        />
        <Button
          type="submit"
          width="100%"
          text="Salvar"
          style={{ marginTop: 'auto' }}
        />
      </Grid>
    </form>
  );
};

export default StockItemForm;
