import styled from 'styled-components';

export const Container = styled.div`
  width: 350px;
  height: calc(100% - 20px);
  background: linear-gradient(180deg, #4c586a 0%, #3f4858 100%);
  border-radius: 20px;
  margin-left: 40px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 1366px) {
    width: 300px;
  }

  > *:not(form) {
    margin-bottom: 40px;
    @media (max-width: 1366px) {
      margin-bottom: 20px;
    }
  }
`;

export const Close = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
`;
