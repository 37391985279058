import React, { memo } from 'react';
import PropTypes from 'prop-types';
import StyleContainer from './styles';

function SwitchLabel({
  id,
  color,
  onClick,
  checked,
  disabled,
  firstOption,
  secondOption,
}) {
  return (
    <StyleContainer
      id={id}
      disabled={disabled}
      color={color}
      firstOption={firstOption}
      secondOption={secondOption}
    >
      <input
        type="checkbox"
        id="switch"
        onClick={onClick}
        checked={checked}
        onChange={() => null}
      />
      <label htmlFor="switch">
        <span>{firstOption}</span>
        <span>{secondOption}</span>
      </label>
    </StyleContainer>
  );
}

SwitchLabel.defaultProps = {
  id: null,
  color: 'primary',
  disabled: false,
};

SwitchLabel.propTypes = {
  id: PropTypes.string,
  /** Cor do estilo do botão (tipo [
      'primary',
      'primary-light',
      'primary-dark',
      'secondary',
      'secondary-light',
      'secondary-dark'
    ] ou color string[rgb, rgba, hex]): */
  color: PropTypes.oneOfType([
    PropTypes.oneOf([
      'primary',
      'primary-light',
      'primary-dark',
      'secondary',
      'secondary-light',
      'secondary-dark',
    ]),
    PropTypes.string,
  ]),
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default memo(SwitchLabel);
