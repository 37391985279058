import styled from 'styled-components';
import { prop } from 'styled-tools';

export default styled.div`
  width: ${prop('width')};
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);

  label {
    font-size: 12px;
  }

  > div {
    display: flex;
    align-items: center;
    > svg {
      margin-right: 5px;
    }
  }

  textarea {
    background-color: transparent;
    color: #fff;
    border: none;
    padding: 5px 0 8px;
    flex: 1;
    height: 80px;
  }
`;
