export default {
  colors: {
    primary: '#9489F0',
    secondary: '#74C5E7',
    success: '#8DDE9F',
    warning: '#f2ea6c',
    danger: '#E77474',
    text: '#5a5a5a',
    grayForm: '#e8e8e8',
    grayText: '#5a5a5a',
    grayLight: '#f6f6f6',
    gray: '#525F72',
    grayDark: '#272e37',
    graySecondary: '#657183',
    white: '#fff',
    icon: '#0098ad',
    background: '#181d23',
  },

  fonts: {
    primary: `'Lato', sans-serif`,
    secondary: `'Rubik', sans-serif`,
  },

  breakpoints: {
    xsmall: '400px',
    small: '600px',
    medium: '800px',
    large: '1000px',
    xlarge: '1120px',
    xxlarge: '1280px',
  },

  containers: {
    xlarge: '1320px',
    large: '1280px',
    medium: '980px',
    small: '800px',
  },
};
