import React from 'react';
import Container from './styles';

const Grid = ({
  width,
  direction,
  justifyContent,
  alignItems,
  children,
  marginBetweenItems,
  style,
}) => (
  <Container
    style={style}
    width={width}
    direction={direction}
    justifyContent={justifyContent}
    alignItems={alignItems}
    marginBetweenItems={marginBetweenItems}
  >
    {children}
  </Container>
);

export default Grid;
