import styled from 'styled-components';

export const Center = styled.div`
  display: flex;
  justify-content: center;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
  }

  p {
    font-size: 14px;
    color: #fff;
    margin: 5px 0 0;
  }

  > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  > *:not(:first-child) {
    margin-top: 20px;
  }
`;
