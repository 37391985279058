import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { Container } from './styles';

const Button = ({ width, style, text, icon, color, disabled, onClick }) => (
  <Container
    onClick={onClick}
    color={color}
    width={width}
    style={style}
    disabled={disabled}
  >
    {icon && <Icon icon={icon} size={14} />}
    {text && <span>{text}</span>}
  </Container>
);

Button.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

export default Button;
