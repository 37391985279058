/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Container from './styles';

const Input = ({
  label,
  icon,
  type,
  name,
  placeholder,
  value,
  onChange,
  width,
  required,
  ...props
}) => (
  <Container width={width}>
    {label && <label>{label}</label>}
    <div>
      {icon && (
        <Icon
          icon={icon}
          style={{ fontSize: 11, color: '#fff', opacity: 0.7 }}
        />
      )}
      <input
        id={`field-${name}`}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value)}
        required={required}
        {...props}
      />
    </div>
  </Container>
);

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  required: true,
};

export default Input;
