import React from 'react';
import StockEntryProvider from '../../contexts/StockEntryContext';
import Grid from '../../components/Grid';
import Title from '../../components/Title';
import StockEntryInfo from '../../components/StockEntryInfo';
import StockEntryItems from '../../components/StockEntryItems';

const StockEntry = () => (
  <StockEntryProvider>
    <Grid
      direction="column"
      marginBetweenItems={40}
      style={{ paddingBottom: 50 }}
    >
      <Title>Compras</Title>
      <StockEntryInfo />
      <StockEntryItems />
    </Grid>
  </StockEntryProvider>
);

export default StockEntry;
