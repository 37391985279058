import React, { useReducer, useContext, createContext } from 'react';

const StockEntryContext = createContext();

export const useStockEntryContext = () => useContext(StockEntryContext);

export default function StockEntryProvider({ children }) {
  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      orderNF: '',
      idSupplier: '',
      purchaseDate: '',
      notes: '',
      items: [
        {
          idInput: '',
          amount: '',
          price: '',
        },
      ],
    }
  );

  return (
    <StockEntryContext.Provider value={{ formData, setFormData }}>
      {children}
    </StockEntryContext.Provider>
  );
}
