import React from 'react';
import Container from './styles';
import image from '../../assets/images/dgtl.png';
import Grid from '../Grid';

const CardEvent = () => (
  <Container>
    <img src={image} alt="Event" />
    <div>
      <Grid style={{ width: 'auto' }} direction="column" alignItems="center">
        <span>28</span>
        <p>MAR</p>
      </Grid>
      <h2>Terraza presents: O tal do Bicho Piruleta</h2>
    </div>
  </Container>
);

export default CardEvent;
