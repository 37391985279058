import styled from 'styled-components';
import { theme } from 'styled-tools';

export default styled.div`
  position: relative;

  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
  }

  label {
    padding: 7px 12px;
    border: 1px solid ${theme('colors.graySecondary')};
    border-radius: 100px;
    display: block;
    cursor: pointer;
    font-size: 14px;
  }

  input:checked + label {
    background-color: ${theme('colors.secondary')};
    border-color: ${theme('colors.secondary')};
  }
`;

export const Group = styled.div`
  display: flex;
  justify-content: space-between;
`;
