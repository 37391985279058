import styled from 'styled-components';

export default styled.div`
  background: linear-gradient(180deg, #4c586a 0%, #3f4858 100%);
  border-radius: 6px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 320px;
  z-index: 10;
  box-shadow: 0 0 10px rgba(63, 72, 88, 0.7);

  > *:not(:last-child) {
    margin-bottom: 30px;
  }
`;
