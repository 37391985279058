import styled, { css } from 'styled-components';
import { prop, ifProp, switchProp } from 'styled-tools';

export default styled.div`
  width: ${prop('width', '100%')};
  display: flex;
  flex-direction: ${prop('direction', 'row')};
  justify-content: ${prop('justifyContent', 'flex-start')};
  align-items: ${prop('alignItems', 'flex-start')};

  ${ifProp(
    'marginBetweenItems',
    css`
      > *:not(:last-child) {
        ${switchProp(
          'direction',
          {
            column: css`
              margin-bottom: ${prop('marginBetweenItems')}px;
            `,
          },
          css`
            margin-right: ${prop('marginBetweenItems')}px;
          `
        )}
      }
    `
  )}
`;
