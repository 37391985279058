import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { prop, ifProp } from 'styled-tools';

export const Container = styled.div`
  width: ${ifProp('collapsed', '50px', '220px')};
  height: 100vh;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #181d23;
  transition: width ease-in-out 0.2s;
`;

export const Menu = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 30px;
  }
`;

export const MenuIcon = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
`;

export const MenuLink = styled(Link)`
  width: 100%;
  display: flex;
  text-decoration: none;
  align-items: center;
  position: relative;
  height: 30px;

  > span {
    font-size: 14px;
    letter-spacing: 3px;
    margin-left: 10px;
  }

  &.active {
    &::before {
      content: '';
      width: 4px;
      height: 100%;
      border-radius: 0px 4px 4px 0px;
      background: ${prop('theme.colors.primary')};
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

export const Collapse = styled.button`
  position: absolute;
  top: 20px;
  left: 25px;
  transform: translateX(-50%);
`;
