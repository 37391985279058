import React from 'react';
import { useStockEntryContext } from '../../contexts/StockEntryContext';
import Grid from '../Grid';
import Title from '../Title';
import Input from '../Input';
import Select from '../Select';

const StockEntryInfo = () => {
  const { formData, setFormData } = useStockEntryContext();

  return (
    <Grid direction="column" marginBetweenItems={12}>
      <Title size={12} style={{ textTransform: 'uppercase', opacity: 0.8 }}>
        Informações da compra
      </Title>
      <Input
        label="Order NF"
        name="orderNF"
        value={formData.orderNF}
        onChange={value => setFormData({ ...formData, orderNF: value })}
      />
      <Grid marginBetweenItems={20}>
        <Select
          label="Fornecedor"
          name="supplier"
          value={formData.idSupplier}
          onChange={value => setFormData({ ...formData, idSupplier: value })}
        >
          <option>Fornecedor 1</option>
          <option>Fornecedor 2</option>
        </Select>
        <Input
          label="Data de recebimento"
          name="orderNF"
          value={formData.purchaseDate}
          onChange={value => setFormData({ ...formData, purchaseDate: value })}
        />
      </Grid>
      <Input
        label="Observações"
        name="notes"
        value={formData.notes}
        onChange={value => setFormData({ ...formData, notes: value })}
      />
    </Grid>
  );
};

export default StockEntryInfo;
