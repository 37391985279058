import styled from 'styled-components';

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * {
    width: 23%;
    &:nth-child(n + 5) {
      margin-top: 30px;
    }
    &:not(:nth-child(4n + 0)) {
      margin-right: 2.666%;
    }
  }
`;
