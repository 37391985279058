export default {
  inputs: {
    get: '/inputs',
    post: '/inputs',
    put: '/inputs/:id',
    delete: '/inputs/:id',
  },

  inputCategories: {
    get: '/input-categories',
  },

  inputTypes: {
    get: '/input-types',
  },

  inputLog: {
    post: '/input-logs',
  },
};
