import styled from 'styled-components';
import { theme, ifProp } from 'styled-tools';

export const Container = styled.div`
  width: 100%;
  background: #3f4c5b;
  box-shadow: -10px 9px 21px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 20px 0;
  position: relative;

  &::after {
    content: '';
    width: 1px;
    height: calc(100% - 50px);
    background: linear-gradient(
      to bottom,
      ${theme('colors.primary')} 75%,
      transparent
    );
    position: absolute;
    top: 28px;
    left: 20px;
  }
`;

export const Row = styled.div`
  padding: 0 20px 20px 40px;
  position: relative;

  &:not(:first-child) {
    padding-top: 20px;
  }

  &::after {
    content: '';
    width: calc(100% - 40px);
    height: 1px;
    background-color: rgba(82, 95, 114, 0.32);
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: ${theme('colors.primary')};
    position: absolute;
    top: 6px;
    left: 15px;
  }
`;

export const ButtonIcon = styled.button`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${ifProp(
    'add',
    theme('colors.success'),
    theme('colors.danger')
  )};
`;

export const Counter = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  color: ${theme('colors.secondary')};
  letter-spacing: 1px;
`;
