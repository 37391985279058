import styled from 'styled-components/macro';
import { ifProp, prop } from 'styled-tools';

export default styled.div`
  width: ${prop('width', '200px')};
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  opacity: ${ifProp('disabled', 0.4, 1)};
  pointer-events: ${ifProp('disabled', 'none', 'auto')};
  cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
  position: relative;
  border: 1px solid #657183;

  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
  }

  label {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 100px;
    padding: 5px;
    display: flex;
    font-size: 14px;
    line-height: 38px;
  }

  span {
    width: 50%;
    text-align: center;
    z-index: 1;
    font-weight: 500;
  }

  label:after {
    content: '';
    width: 50%;
    height: calc(100% - 10px);
    background: ${prop('theme.colors.secondary')};
    border-radius: 25px;
    transition: 0.3s;
    color: #fff;
    font-size: 14px;
    line-height: 38px;
    text-align: center;
    position: absolute;
    top: 5px;
    left: 5px;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 20px;
  }
`;
