import { useEffect, useRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  registerResource,
  fetchAll,
  fetchOne,
  create,
  update,
  remove,
  reset,
} from './resources';

const useResource = (
  resource,
  {
    id = null,
    params = {},
    autoFetch = true,
    updateSchema = undefined,
    pagination = false,
    perPage = 20,
    infiniteScroll = false,
    preventReset = false,
    onAutoFetchResponse = undefined,
    namespace = undefined,
  } = {}
) => {
  const dispatch = useDispatch();
  const resourceState = useSelector(
    state => state.resources[namespace || resource]
  );
  const paginationParams =
    pagination || infiniteScroll
      ? {
          page:
            resourceState && resourceState.page ? resourceState?.page + 1 : 1,
          perPage,
        }
      : {};

  const paginationRef = useRef(null);

  useImperativeHandle(paginationRef, () => ({
    paginationParams,
  }));

  useEffect(() => {
    if (!resourceState) {
      dispatch(registerResource(resource, namespace, pagination, perPage));
    }

    if (autoFetch) {
      if (id) {
        dispatch(
          fetchOne(resource, id, params, onAutoFetchResponse, namespace)
        );
      } else {
        dispatch(
          fetchAll(
            resource,
            namespace,
            { ...params, ...paginationParams },
            infiniteScroll,
            onAutoFetchResponse,
            preventReset
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...resourceState,
    reset: () => dispatch(reset(resource, namespace)),
    fetchAll: ({ fetchParams, page, onResponse } = {}) =>
      dispatch(
        fetchAll(
          resource,
          namespace,
          {
            ...params,
            ...fetchParams,
            ...paginationRef?.current?.paginationParams,
            ...(page && { page }),
          },
          infiniteScroll,
          onResponse,
          preventReset
        )
      ),
    fetchOne: ({ idParam, fetchParams, onResponse } = {}) =>
      dispatch(
        fetchOne(
          resource,
          idParam || id,
          { ...params, ...fetchParams },
          onResponse,
          namespace
        )
      ),
    create: ({ data, createParams, onResponse } = {}) => {
      dispatch(
        create(
          resource,
          namespace,
          data,
          updateSchema,
          {
            ...params,
            ...createParams,
          },
          onResponse
        )
      );
    },
    update: ({ idUpdate, data, updateParams, idSchema, onResponse } = {}) =>
      dispatch(
        update(
          resource,
          namespace,
          idUpdate || id,
          data,
          updateSchema,
          idSchema,
          {
            ...params,
            ...updateParams,
          },
          onResponse
        )
      ),
    remove: ({ idRemove, deleteParams, idSchema, onResponse } = {}) =>
      dispatch(
        remove(
          resource,
          namespace,
          idRemove,
          { ...params, ...deleteParams },
          updateSchema,
          idSchema,
          onResponse
        )
      ),
  };
};

export default useResource;
