import styled from 'styled-components';
import { prop } from 'styled-tools';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: 30px;
  }
`;

export const StyledLink = styled(NavLink)`
  color: #fff;
  opacity: 0.7;
  transition: 0.2s;
  font-size: 14px;
  text-decoration: none;
  position: relative;
  padding-bottom: 8px;

  &:hover,
  &.active {
    font-weight: 500;
    opacity: 1;

    &::after {
      content: '';
      width: 70%;
      height: 4px;
      border-radius: 0 0 4px 4px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: ${prop('theme.colors.primary')};
      transition: 0.2s;
    }
  }

  &:hover {
    &::after {
      background: ${prop('theme.colors.secondary')};
    }
  }
`;
