import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  > svg {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
